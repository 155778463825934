import React, {useState} from 'react';
import moment from 'moment';
import './style.scss';
import axios from "axios";
import toast from 'react-hot-toast';


const CustomMessageForm = ({onFormSent}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [arrivalInputType, setArrivalInputType] = useState('text');
  const [departureInputType, setDepartureInputType] = useState('text');
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);

  const handleArrivalDateFocus = () => {
    setArrivalInputType('date');
  };
  
  const handleArrivalDateBlur = () => {
    setArrivalInputType('text')
  };

  const handleDepartureDateFocus = () => {
    setDepartureInputType('date');
  };
  
  const handleDepartureDateBlur = () => {
    setDepartureInputType('text');
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'phoneNumber') {
      const sanitizedValue = value.replace(/[^\d+() \-]/g, '');
      setPhoneNumber(sanitizedValue);
    } else if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastName(value);
    } else if (name === 'arrivalDate') {
      setArrivalDate(value);
    } else if (name === 'departureDate') {
      setDepartureDate(value);
    } else if (name === 'messageContent') {
      setMessageContent(value);
    }
  };
  

  const sanitizePhoneNumber = (input) => {
    // Remove any non-digit, non-plus characters
    const sanitizedValue = input.replace(/[^+\d]/g, '');
  
    // Ensure +1 at the beginning if not already there
    const formattedValue = sanitizedValue.startsWith('+') ? sanitizedValue : `+1${sanitizedValue}`;
  
    return formattedValue;
  }
  
  const convertDate = (dateString) => {
    if (dateString) {
      const formattedDate = moment(dateString).format("MM/DD/YYYY");
      return formattedDate
    } else {
      return '';
    }
  }
  
  const alertToast = (message, color, icon, duration = 3000) => {
    const id = toast((t) => (
    <div>
        <span onClick={() => toast.dismiss(t.id)}>
        {message}
        </span>
    </div>
    ), {
    position: 'top-right',
    icon: icon,
    duration: duration,
    style: {
        background: color,
        color: '#ffffff',
    }
    });
    return id;
  }
  
  const handleSubmit = async(event) => {
    setIsSendButtonDisabled(true);
    event.preventDefault();
    
    try {
      const hotelId = localStorage.getItem('marie_hotel_id')
      const sanitizedPhoneNumber = sanitizePhoneNumber(phoneNumber)
      alertToast(`Sending message to ${sanitizedPhoneNumber}`, '#01a0c6', '🚀', 3000);
      const addGuestBody = {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: sanitizedPhoneNumber,
        checkinDate: arrivalDate,
        checkoutDate: departureDate
      }
      if(!addGuestBody.phoneNumber){
        alertToast("Please add a phone number", "#cf4343", "⚠️");
        setIsSendButtonDisabled(false);
        return;
      }
      const hotelPhoneNumber = localStorage.getItem('marie_hotel_phone_number')
      const initConversationBody = {
        hotelId: hotelId, 
        guestPhoneNumber: sanitizedPhoneNumber, 
        hotelPhoneNumber: hotelPhoneNumber, 
        messageContent: messageContent
      }
      if(!initConversationBody.messageContent){
        alertToast("Message cannot be blank", "#cf4343", "⚠️");
        setIsSendButtonDisabled(false);
        return;
      }
      const addGuestResponse = await axios.post(`/api/guests/${hotelId}/add-single-guest`, addGuestBody);
      const initConversationResponse = await axios.post("/api/chat/send-message", initConversationBody);
      onFormSent();
      alertToast(`Message sent`, '#01a0c6', '✓', 3000);
    } catch (error) {
      console.error('there was an error sending the message', error)
      alertToast("Unable to send message", '#cf4343', '⚠️')
    }
    setIsSendButtonDisabled(false);
  }
  
      return (
        <form onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <input 
              type="text"
              name="firstName" 
              value={firstName} 
              onChange={handleChange} 
              placeholder="Guest First Name" />
            <input 
              type="text" 
              name="lastName"
              value={lastName} 
              onChange={handleChange} 
              placeholder="Guest Last Name" />
          </div>
          <div className="input-wrapper">
            <input 
              type="tel" 
              value={phoneNumber}
              name="phoneNumber"
              onChange={handleChange} 
              placeholder="Guest Phone Number" />
          </div>
          <div className="input-wrapper">
            <input 
              placeholder="Arrival Date"
              type={arrivalInputType}
              onFocus={handleArrivalDateFocus}
              onBlur={handleArrivalDateBlur}
              name="arrivalDate"
              value={arrivalInputType == 'date' ? arrivalDate : convertDate(arrivalDate)}
              onChange={handleChange} />
            <input 
              placeholder="Departure Date"
              type={departureInputType}
              onFocus={handleDepartureDateFocus}
              onBlur={handleDepartureDateBlur}
              name="departureDate"
              value={departureInputType == 'date' ? departureDate : convertDate(departureDate)} 
              onChange={handleChange} />
          </div>
          <div className="input-wrapper textarea-wrapper">
            <textarea
              name="messageContent" 
              id="message-text"
              value={messageContent} 
              onChange={handleChange} 
              placeholder="Write message here">
            </textarea>
          </div>
          <div className="submit-wrapper">
            {/* TODO: Finish file input stuff */}
            <div className="spacer"></div>
            <input type="submit" value="Send" disabled={isSendButtonDisabled}/>
          </div>
        </form>
      );
}

export default CustomMessageForm