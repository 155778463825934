import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PaginationFooter from '../PaginationFooter/PaginationFooter';
import GuestUploadForm from './GuestUploadForm/GuestUploadForm';
import SideChat from '../SideChat/SideChat';
import toast from 'react-hot-toast';
import loadingSpinner from '../../assets/eclipse-1s-200px.gif'
import './style.scss';

const LIST_SIZE = 10;

const ArrivalsPanel = () => {
    const [guests, setGuests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const hotelId = localStorage.getItem('marie_hotel_id');

    function getUniqueConversationPreviews() {
        return guests.length;
	}

    function dateValidate(date){
        return !isNaN(new Date(date));
    }
    const alertToast = (message, color, icon) => {
        toast((t) => (
            <div>
                <span onClick={() => toast.dismiss(t.id)}>
                {message}
                </span>
          </div>
        ), {
            position: 'top-right',
            icon: icon,
            duration: 3000,
            style: {
                background: color,
                color: '#ffffff',
            }
        });
    }
    const loadGuests = async () => {
        try {
            const hotelId = localStorage.getItem('marie_hotel_id');
            const response = await axios.get(`/api/guests/${hotelId}/all`);
            const sanitizedGuests = response.data.filter(guest => 
                guest.PhoneNumber && 
                guest.PhoneNumber !== "toArray" &&
                dateValidate(guest.checkinDate)
            );
            sanitizedGuests.sort((a, b) => a.checkinDate.localeCompare(b.checkinDate));
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
            const upcomingGuests = sanitizedGuests.filter(guest => {;
                const [year, month, day] = guest.checkinDate.split("-")
                const guestCheckInDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
                return guestCheckInDate >= today;

            });
            setGuests(upcomingGuests);
            setIsLoaded(true);
        } catch (error) {
            console.error('Failed to fetch guests', error);
        }
    };
    const addGuestEntry = useCallback((guestData) => {
        const transformedGuestData = {
            ...guestData,
            Name: {
                First: guestData.firstName,
                Last: guestData.lastName,
            },
            PhoneNumber: guestData.phoneNumber,
            checkinDate: guestData.checkinDate,
            checkoutDate: guestData.checkoutDate,
            roomNumber: guestData.roomNumber
        };
    
        setGuests(prevGuests => [...prevGuests, transformedGuestData]);
    });

    const toggleSideChat = (guestData) => {
    //   if (!isChatOpen) {
    //       setSelectedData({
    //           guestData
    //       });
    //   }
    //   setIsChatOpen(!isChatOpen);
  };
    async function deleteEntry(phoneNumber){
      try{
        const response = await axios.delete(`/api/guests/${hotelId}/${phoneNumber}/delete`);
        setGuests(guests.filter(guest => guest.PhoneNumber !== phoneNumber));
        alertToast('Guest deleted', '#01a0c6', '✓');
      } catch (error) {
            alertToast('Failed to delete guest', '#CF4343', '⚠️');
            console.error('Failed to delete guest', error);
      }
    }

    useEffect(() => {
        loadGuests();
    },[]);

    return (
        <div className="arrivals-panel-body">
            <div className="arrivals-panel-content">
                <div className='arrivals-panel-header'>
                    <div className="arrivals-panel-title">Arrivals</div>
                </div>
                <div className="add-guest-header">Add Guest</div>
                <div className="guest-upload-container">
                    <GuestUploadForm onGuestAdd={addGuestEntry}></GuestUploadForm>
                </div>
                    {isLoaded ? (
                        <>
                            <div className="arrivals-header">Upcoming Arrivals</div>
                            <div className="guest-list-body">
                                <div className="guest-list-header">
                                    <div className="row-header-container">
                                        <div className="row-header">Name</div>
                                    </div>
                                    <div className="row-header-container">
                                        <div className="row-header">Arrival</div>
                                    </div>
                                    <div className="row-header-container">
                                        <div className="row-header">Departure</div>
                                    </div>
                                    <div className="row-header-container">
                                        <div className="row-header">Phone</div>
                                    </div>
                                    <div className='row-header-container'>
                                        <div className='row-header'>Room Number</div>
                                    </div>
                                    <div className='row-header-container'>
                                        <div className='row-header'></div>
                                    </div>
                                </div>
                            </div>                
                        </>
                    ) : (
                        <>

                        </>
                    )}
                <div className="guest-list-container">
                    {isLoaded ? (
                        <>
                            {guests.slice((currentPage - 1) * LIST_SIZE, currentPage * LIST_SIZE).map((guest) => (
                                <div key={guest.PhoneNumber}>
                                    <div className='arrival-entry-row' >
                                        <div className="arrival-container" onClick={() => toggleSideChat(guest)}>
                                            <div className="arrival-label" >{guest.Name?.First} {guest.Name?.Last}</div>
                                        </div>
                                        <div className="arrival-container" onClick={() => toggleSideChat(guest)}>
                                            <div className="arrival-label">{guest.checkinDate}</div>
                                        </div>
                                        <div className="arrival-container" onClick={() => toggleSideChat(guest)}>
                                            <div className="arrival-label">{guest.checkoutDate}</div>
                                        </div>
                                        <div className="arrival-container" onClick={() => toggleSideChat(guest)}>
                                            <div className="arrival-label">{guest.PhoneNumber}</div>
                                        </div>
                                        <div className="arrival-container" onClick={() => toggleSideChat(guest)}>
                                            {guest.roomNumber ? (
                                                <div className='arrival-label'>{guest.roomNumber}</div>
                                            ):(
                                                <div className='arrival-label'>n/a</div>
                                            )}
                                        </div>
                                        <div className="delete-button-container">
                                            <div className="delete-button" onClick={() => deleteEntry(guest.PhoneNumber)}>Delete</div>
                                        </div>
                                    </div>
                                    <SideChat 
                                        data={selectedData}
                                        constructor={'arrivals'}
                                        id={guest.PhoneNumber}
                                        isOpen={isChatOpen} 
                                        toggleSideChat={toggleSideChat} 
                                    />
                                </div>
                            ))}
                            <PaginationFooter
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                numberOfTotalPreviews={getUniqueConversationPreviews()}
                                numberOfPreviews={currentPage}
                                pageSize={10}
                            />
                        </>
                    ) : (
                        <>
                            <div style={{display: 'flex'}}>
                                <img src={loadingSpinner} style={{margin: '0 auto'}}/>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ArrivalsPanel;