import React, {useState} from 'react';
import './style.scss';
import axios from "axios";
import toast from 'react-hot-toast';


const BlastMessageForm = ({onFormSent}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [allInHouse, setAllInHouse] = useState(false);
    const [isDateRangeDisabled, setIsDateRangeDisabled] = useState(false);
    const [isArrivalDateRangeDisabled, setIsArrivalDateRangeDisabled] = useState(false);
    const [isMessageTextDisabled, setIsMessageTextDisabled] = useState(true);
    const [messageContent, setMessageContent] = useState('');
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

    const toggleAllInHouse = () => {
        setAllInHouse((prevState) => !prevState.allInHouse);
        setIsArrivalDateRangeDisabled((prevState) => !prevState.allInHouse);
        setStartDate((prevState) => !prevState.allInHouse ? '' : prevState.startDate);
        setEndDate((prevState) => !prevState.allInHouse ? '' : prevState.endDate);
        setIsMessageTextDisabled((prevState) => !prevState.isMessageTextDisabled)
    }
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    
        if (name === 'startDate' || name === 'endDate') {
            setAllInHouse(false);
            setIsMessageTextDisabled(false);
        }
    }

    const alertToast = (message, color, icon, duration = 3000) => {
        const id = toast((t) => (
        <div>
            <span onClick={() => toast.dismiss(t.id)}>
            {message}
            </span>
        </div>
        ), {
        position: 'top-right',
        icon: icon,
        duration: duration,
        style: {
            background: color,
            color: '#ffffff',
        }
        });
        return id;
    }

    const getDatesBetween = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(startDate);
        
        while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray.map(date => date.toISOString().split('T')[0]);
    }
    

    const handleSubmit = async(event) => {
        event.preventDefault();
        setIsSendButtonDisabled(true);
        try {
            const hotelId = localStorage.getItem('marie_hotel_id')
            const allGuestsToMessage = await axios.get(`/api/guests/${hotelId}/all`);
            const hotelPhoneNumber = localStorage.getItem('marie_hotel_phone_number');
            if((startDate !== '' || endDate !== '') && !allInHouse){
                const toastId = alertToast('Sending messages...', '#01a0c6', '⏳', Infinity)
                const startDate = startDate || endDate;
                const endDate = endDate || startDate;
                let dates = [startDate, endDate];
                dates.sort();
                const allDates = getDatesBetween(dates[0], dates[1]);
                const phoneNumberList = new Set();
                for (const guest of allGuestsToMessage?.data){
                    const initConversationBody = {
                        hotelId: hotelId,
                        guestPhoneNumber: guest?.PhoneNumber,
                        hotelPhoneNumber: hotelPhoneNumber,
                        messageContent: messageContent,
                    }
                    for (const date of allDates) {
                        if(date >= guest?.checkinDate && date < guest?.checkoutDate && !phoneNumberList.has(guest?.PhoneNumber)){
                            await axios.post("/api/chat/send-message", initConversationBody);
                            phoneNumberList.add(guest?.PhoneNumber);
                        }
                    }
                }
                toast.dismiss(toastId);
            }
            else if(allInHouse === true){
                const toastId = alertToast('Sending messages...', '#01a0c6', '⏳');
                const formattedDate = formatDate();            
                allGuestsToMessage?.data.map(async guest => {
                    const initConversationBody = {
                        hotelId: hotelId,
                        guestPhoneNumber: guest?.PhoneNumber, 
                        hotelPhoneNumber: hotelPhoneNumber,
                        messageContent: messageContent,
                    }
                    if(formattedDate >= guest?.checkinDate && formattedDate < guest?.checkoutDate){
                        await axios.post("/api/chat/send-message", initConversationBody);
                    }
                });
                toast.dismiss(toastId);
            }
            onFormSent()
        } catch (error) {
            console.error('there was an error sending the message', error)
            alertToast('Message Failed To send', '#CF4343', '⚠️');
            setIsSendButtonDisabled(false);
        }
        setIsSendButtonDisabled(false);
    }

    const formatDate = () => {
        const date = new Date(Date.now());
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <form className='blast-messaging-form' onSubmit={handleSubmit}>
            <div className="blast-input-wrapper">
                <input 
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={handleChange}
                    disabled={isDateRangeDisabled}
                    className={isDateRangeDisabled ? "entry-disabled" : ""}
                />
                <span> to </span>
                <input 
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={handleChange}
                    disabled={isDateRangeDisabled}
                    className={isDateRangeDisabled ? "entry-disabled" : ""}
                />
                <div className='or-label'>
                    -OR-
                </div>
                <input
                    className={(allInHouse) ? "all-in-house-button-on" : "all-in-house-button-off"}
                    name="allInHouse"
                    type={'button'}
                    value="All In House"
                    onClick={toggleAllInHouse}
                ></input>
            </div>
            <div className="input-wrapper textarea-wrapper">
                <textarea
                    name="messageContent" 
                    id="message-text"
                    value={messageContent} 
                    onChange={handleChange} 
                    placeholder="Write message here..."
                    disabled={(startDate !== '' || endDate !== '' || allInHouse !== false) ? "" : "disabled"}
                    className={(startDate !== '' || endDate !== '' || allInHouse !== false) ? "": "entry-disabled"}
                >
                </textarea>
            </div>
            <div className="blast-submit-wrapper">
                <div className="spacer"></div>
                <input type="submit" value="Send" id='blast-send-button' disabled={((allInHouse === '' && startDate === '') || messageContent === '') ? "disabled" : ""} className={`blast-send-button ${(allInHouse === '' && startDate === '') || messageContent === '' ? "send-button-disabled" : ""}`}/>
            </div>
        </form>
    );
  
}

export default BlastMessageForm