import React, {useState, useEffect, useRef} from 'react';
import RequestsFilter from './RequestsFilter/RequestsFilter';
import PaginationFooter from '../PaginationFooter/PaginationFooter';
import GuestRequest from './GuestRequest/GuestRequest';
import axios from 'axios';
import toast from 'react-hot-toast';
import './style.scss';
import '../InboxPanel/style.scss';
import customMessageButton from "../../assets/custom-message-button.png";
import customMessageIcon from "../../assets/custom-message-icon.png";
import blastMessageIcon from "../../assets/blast-message-icon.png";
import Modal from '../Modal/Modal';
import CustomMessageForm from '../Modal/CustomMessageForm/CustomMessageForm';
import SideChat from '../SideChat/SideChat';
import BlastMessageForm from '../Modal/BlastMessageForm/BlastMessageForm';
import { useSearchParams } from 'react-router-dom';

const RequestsPanel = ({token, hotelId, arrivalData, socket, hotelData}) => {
	const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [activeStatusFilters, setActiveStatusFilters] = useState([]);
    const [isSideChatOpen, setIsSideChatOpen] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [conversationPreviews, setConversationPreviews] = useState([]);
    const [conversationsLoaded, setConversationsLoaded] = useState(false);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [currentGuestPhoneNumber, setCurrentGuestPhoneNumber] = useState(null);
    const [manualConversationIds, setManualConversationIds] = useState([]);
    const [manualTimeouts, setManualTimeouts] = useState([]);
    const [conversationName, setConversationName] = useState("");
    const [showBlockOverlay, setShowBlockOverlay] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sideChatData, setSideChatData] = useState(null);
    const [isBlocked, setIsBlocked] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [openOptions, setOpenOptions] = useState(false);
	const [urlParams, setUrlParams] = useSearchParams();
	const [listSize, setListSize] = useState(12);
	const containerRef = useRef(null);


	const handleOpenModal = (messageType) => {
		setShowModal(true);
		setMessageType(messageType);
	}

	const handleCloseModal = () => {
		setShowModal(false);
	}

	useEffect(() => {
		fetchRequests();
		const intervalId = setInterval(fetchRequests, 7000);
		return () => {
			clearInterval(intervalId);
		}
	}, []);

	useEffect(() => {
		const handleWebsocketMessage = async (message) => {
			try{
				switch(message.type){
					case "connectionSuccess":
						console.log("Websocket Connected");
						break;
					case "setConversationPreviews":
						console.log("setting conversationPreviews");
						setConversationsLoaded(true);
						setConversationPreviews(message.conversationPreviews);
						break;
					case "setConversations":
						console.log("setting conversations");
						setConversations(message.conversations);
						setCurrentConversation(message?.conversations?.filter(conversation => conversation?.id === currentGuestPhoneNumber));
						setConversationsLoaded(true);
						break;
					case "setGuestConvos":
						console.log("setting conversations and conversationPreviews");
						setConversationPreviews(message.conversationPreviews);
						setConversations(message.conversations);
						setConversationsLoaded(true);
						break;
					default:
						console.error("Unknown websocket message");
				}
			}catch(error){
				console.error("Error in handleWebsocketMessage:", error);
			}
		}
		
		socket?.on('client', (message) =>{
			console.log("Getting message");
			handleWebsocketMessage(message);
		})

		socket?.emit("server", {
			type: "getGuestConvos"
		});		
		// Check blocked status after initializing conversations
		if (currentGuestPhoneNumber) {
			getBlockedStatus(currentGuestPhoneNumber);
		}
		
		// Assume inbox is empty if we haven't grabbed a conversation in 7 seconds
		const timeoutId = setTimeout(() => {
			setConversationsLoaded(true);
		}, 7000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('guestPhoneNumber')) {
			let param = urlParams.get("guestPhoneNumber");
			if(param && !currentGuestPhoneNumber && param !== currentGuestPhoneNumber){
				urlParams.set("guestPhoneNumber", currentGuestPhoneNumber);
				const newRelativePathQuery = window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '');
				window.history.replaceState(null, '', newRelativePathQuery);
				checkForSpecifiedConversation(param);
			}
		}
	},[currentGuestPhoneNumber]);
	//********************************************************************************************************************* */
	useEffect(() => {
        const adjustListSize = () => {
            if (containerRef.current) {
                const containerHeight = containerRef.current.clientHeight;
                const rowHeight = 50;
                const footerHeight = 35;
                const availableHeight = containerHeight - footerHeight;
                const rowsVisible = Math.floor(availableHeight / rowHeight);
                setListSize(rowsVisible);
            }
        };

        adjustListSize();
        window.addEventListener('resize', adjustListSize);

        return () => {
            window.removeEventListener('resize', adjustListSize);
        };
    }, []);
	const checkForSpecifiedConversation = async (id) => {
		const conversation = await getConversationFromPhoneNumber(id);
		if (conversation) {
			const name = conversation.Name?.First + " " + conversation.Name?.Last ?? "Guest";
			displayConversation(id, name, id);
		}
	};

	const adjustManualTimeouts = (conversationId, shouldAdd, timestamp) => {
		if (shouldAdd) {
			const now = new Date().getTime();
			const difference = timestamp - now;
			const timeoutId = setTimeout(() => {
				removeFromManualConversations(conversationId);
			}, difference);

			setManualTimeouts(prevManualTimeouts => [
				...prevManualTimeouts,
				{
					conversationId,
					timeoutId,
				}
			]);
		} else {
			const pairing = manualTimeouts.find((pairing) => pairing.conversationId === conversationId);
			if (pairing) {
				setManualConversationIds(prevState => 
					prevState.filter((pairing) => pairing.conversationId !== conversationId)
				);
				clearTimeout(pairing.timeoutId);
			}
		}
	};

	const updateManualTimeoutEntryInDatabase = (hotelId, guestPhonenumber, timestamp) => {
		axios
			.post("/api/chat/set-to-manual", {
				hotelId: hotelId,
				guestPhoneNumber: guestPhonenumber,
				timestamp: timestamp.toString(),
			})
			.then((response) => {});
	};

	const addToManualConversations = (conversationId, timestamp) => {
		setManualConversationIds(prevState => [
			...prevState,
			conversationId
		]);
		adjustManualTimeouts(conversationId, true, timestamp);
	};

	const removeFromManualConversations = (conversationId) => {
		setManualConversationIds(prevState =>
			prevState.filter((id) => id !== conversationId)
		);
		adjustManualTimeouts(conversationId, false, null);
	};

	const toggleManualSwitch = (event) => {
		const shouldAdd = event.target.checked;
		if (shouldAdd) {
			const timestamp = new Date().getTime() + 1 * 60 * 1000 * 15; // TODO: make configurable
			addToManualConversations(currentConversation.sid, timestamp);
			updateManualTimeoutEntryInDatabase(hotelId, currentGuestPhoneNumber, timestamp);
		} else {
			removeFromManualConversations(currentConversation.sid);
			const now = new Date().getTime();
			updateManualTimeoutEntryInDatabase(hotelId, currentGuestPhoneNumber, now);
		}
	};

	const isInManualMode = () => {
		return manualConversationIds.includes(currentConversation.sid);
	};

	// Original Patch
	const getConversationFromPhoneNumber = async (guestPhoneNumber) => {
		try {
			guestPhoneNumber = guestPhoneNumber?.replace(/\s/g, "");
			const guestConversation = await axios.get(`/api/guests/get-by-phone-number/${hotelId}/${guestPhoneNumber}`);
			return guestConversation.data;
		} catch (error) {
			console.error(`Error encountered in getConversationFromPhoneNumber: ${error}`);
		}
	};

	const setSearchParams = (key, value) => {
		var searchParams = new URLSearchParams(window.location.search);
		searchParams.set(key, value);
		var newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
		window.history.pushState(null, "", newRelativePathQuery);
	};

	const displayConversation = async (id, name, phoneNumber) => {
		const currentConversation = await getConversationFromPhoneNumber(phoneNumber);
		const convo = currentConversation?.conversation;
		setSearchParams("guestPhoneNumber", phoneNumber);
		const allConversationContent = {
			...convo,
			guestName: name,
			guestPhoneNumber: phoneNumber,
			id: id,
		};
		setCurrentConversation(convo);
		setConversationName(name);
		setCurrentGuestPhoneNumber(phoneNumber);
		setSideChatData(allConversationContent);
		setIsSideChatOpen(true);
		const blockedStatus = await getBlockedStatus(phoneNumber);
		setIsBlocked(blockedStatus);
	};

	const onBackButtonClicked = () => {
		setCurrentConversation(null);
		window.history.pushState(null, "", window.location.pathname);
	};

	const handleBlockUnblockClick = (event) => {
		event.preventDefault();
		const guestPhoneNumber = currentGuestPhoneNumber;
		if (guestPhoneNumber) {
			if (isBlocked) {
				return unblockUser(guestPhoneNumber);
			} else {
				return blockUser(guestPhoneNumber);
			}
		}
	};

	const blockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/block", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: hotelId,
			});
			if (response.status === 200) {
				setIsBlocked(true);
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	const unblockUser = async (guestPhoneNumber) => {
		try {
			const response = await axios.post("api/chat/unblock", {
				guestPhoneNumber: guestPhoneNumber,
				hotelId: hotelId,
			});
			if (response.status === 200) {
				setIsBlocked(false);
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	const getBlockedStatus = async (guestPhoneNumber) => {
		try {
			const response = await axios.get("api/chat/blocked-status", {
				params: {
					guestPhoneNumber: guestPhoneNumber,
					hotelId: hotelId,
				},
			});
			if (response.status === 200) {
				return response.data.blocked;
			} else {
				return false;
			}
		} catch (error) {
			//   should eventually show some type of feedback to user if it fails
		}
	};

	// method to toggle the block overlay
	const toggleBlockOverlay = () => {
		setShowBlockOverlay(prevState => !prevState);
	};

	const closeSideChat = () => {
		setIsSideChatOpen(false);
		setSideChatData(null);
		const searchParams = new URLSearchParams(window.location.search);
		if(searchParams.has('guestPhoneNumber')){
			searchParams.delete('guestPhoneNumber');
			const newRelativePathQuery = window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : '');
			window.history.replaceState(null, '', newRelativePathQuery);
		}
	};
	//************************************************************************************************************************* */


	const fetchRequests = async () => {
		try{
			const response = await axios.get(`/api/requests/${hotelId}`);
			// TODO: handle non-200s
			const filteredRequests = applyFilters(response.data);
			setRequests(response.data);
			setFilteredRequests(filteredRequests);
		}catch(err){
			console.error("Couldn't fetch the requests");
		}
	}

	const updateRequestStatus = async (guestRequest, status) => {
		try {
			const response = await axios.put('/api/requests', {
				id: guestRequest.id,
				requestStatus: status,
				hotelId: hotelId
			});
			if (response.status === 200) {
				const newRequests = requests.map(request =>
					request.id === guestRequest.id ? { ...request, requestStatus: status } : request
				);
				const filteredRequests = applyFilters(newRequests);
				setRequests(newRequests);
				setFilteredRequests(filteredRequests);
			} else {
				alertToast();
			}
		} catch (error) {
			alertToast();
		}

	}

	const alertToast = () => {
		toast((t) => (
			<span onClick={() => toast.dismiss(t.id)}>
				{`Unable to update request`}
			</span>
		), {
			position: 'top-right',
			icon: '⚠️',
			duration: 5000
		});
	}

	const applyFilters = (requests) => {
		return requests.filter(request =>
			(activeFilters.length === 0 || activeFilters.includes(request.requestCategory)) &&
			(activeStatusFilters.length === 0 || activeStatusFilters.includes(request.requestStatus))
		);
	};
	const onRequestFilterChange = (filters) => {
		const activeFilters = filters.filter(filter => filter !== 'INCOMPLETE' && filter !== 'COMPLETE');
		const activeStatusFilters = filters.filter(filter => filter === 'INCOMPLETE' || filter === 'COMPLETE');
		setActiveFilters(activeFilters);
    	setActiveStatusFilters(activeStatusFilters);
	};

	useEffect(() => {
		const filteredRequests = applyFilters(requests);
		setFilteredRequests(filteredRequests);
	}, [activeFilters, activeStatusFilters, requests]);

	const handleToggleOptions = () => {
		setOpenOptions(!openOptions)
	}
	const openModalCloseOptions = (messageType) => {
		handleOpenModal(messageType);
		handleOptionsOff();
	}
	const handleOptionsOff = () => {
		setOpenOptions(false);
	}
	const handleFormSent = () => {
		handleCloseModal()
	}

	const setNewCurrentPage = (page) => {
		setCurrentPage(page);
	};
	const renderWhichModalMessageType = (messageType) => {
		switch(messageType) {
			case 'blast':
				return (
					<Modal showModal={showModal} closeModal={handleCloseModal}>
						<BlastMessageForm onFormSent={handleFormSent}></BlastMessageForm>
					</Modal>
				)
			case 'custom':
				return (
					<Modal showModal={showModal} closeModal={handleCloseModal}>
						<CustomMessageForm onFormSent={handleFormSent}></CustomMessageForm>
					</Modal>
				)
		}
	}
	return (
		<>
			{renderWhichModalMessageType(messageType)}
			<div className="requests-panel-body">
				<div className="requests-panel-content">
					<div className="inbox-panel-header">
						<div className="requests-panel-title">Inbox</div>
						<div className='option-button-container'>
							<img
								id="custom-message-button"
								className="custom-message-button"
								onClick={handleToggleOptions}
								src={customMessageButton}>
							</img>
							{openOptions ? (
								<>
									<div className='open-options-ctn'>
										<div className='message-icon-ctn'>
											<img className='message-icon' src={customMessageIcon}></img>
											<div className='open-options' onClick={() => openModalCloseOptions('custom')}>
												Send Custom Message
											</div>
										</div>
										<div className='message-icon-ctn'>
											<img className='message-icon' src={blastMessageIcon}></img>
											<div className='open-options' onClick={() => openModalCloseOptions('blast')}>
												Send Blast Message
											</div>
										</div>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					</div>
					<RequestsFilter 
						onChange={onRequestFilterChange}
					/>
					<div className="requests-list-body" ref={containerRef}>
						{filteredRequests
							.slice((currentPage - 1) * listSize, currentPage * listSize)
							.map(request => {
								return <GuestRequest
									request={request}
									updateRequestStatus={updateRequestStatus}
									key={request.id}
									displayConversation={displayConversation}
									hotelId={hotelId}
									filters={hotelData?.RequestFilters}
								>
								</GuestRequest>
							})}
						{
							filteredRequests.length ?
								<PaginationFooter
									currentPage={currentPage}
									setCurrentPage={setNewCurrentPage}
									numberOfTotalPreviews={filteredRequests.length}
									numberOfPreviews={currentPage}
									pageSize={listSize}
								/>
								: null
						}
					</div>
				</div>
				{isSideChatOpen && (
					<SideChat
						data={sideChatData}
						arrivalData={arrivalData}
						hotelId={hotelId}
						conversation={(conversations.find(convo => convo.id === currentGuestPhoneNumber))?.conversation}
						guestPhoneNumber={currentGuestPhoneNumber}
						constructor={"conversations"}
						isOpen={isSideChatOpen}
						toggleSideChat={closeSideChat}
						addToManualConversations={addToManualConversations}
						removeFromManualConversations={removeFromManualConversations}
						toggleManualSwitch={toggleManualSwitch}
						isInManualMode={isInManualMode}
						toggleBlockOverlay={toggleBlockOverlay}
						handleBlockUnblockClick={handleBlockUnblockClick}
						showBlockOverlay={showBlockOverlay}
						onBackButtonClicked={onBackButtonClicked}
						isBlocked={isBlocked}
					/>
				)}
			</div>
		</>
	);
}
export default RequestsPanel;